export const meta: BlockMeta = {
  title: "Visualisation",
  description: "Octons doing things",
  keywords: ["visualisation"],
  icon: "visibility",
  cache: false,
  tags: ["root"],
  postMetaBlock: {
    fieldName: "visualisation",
    postTypes: ["page"],
  },
}

import { OCTAL } from "@components/atoms/Logo"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { useNav } from "@hooks/useNav"
import { defineBlock, EditableText, InnerBlocks, usePostTitleEditor } from "eddev/blocks"
import { Fragment, useEffect, useRef, useState } from "react"

export default defineBlock("home/visualisation", (props) => {
  const gridContainer = useRef<HTMLDivElement>(null)
  const isMd = useBreakpoint("md")
  const navStatus = useNav()

  const colors = [
    {
      color: "pink",
      percentage: 0.6,
    },
    {
      color: "green",
      percentage: 0.1,
    },
    {
      color: "yellow",
      percentage: 0.1,
    },
    {
      color: "orange",
      percentage: 0.1,
    },
    {
      color: "blue",
      percentage: 0.1,
    },
    {
      color: "grey",
      percentage: 0.1,
    },
  ]

  let cursorX = 0
  let cursorY = 0

  const [colorArray, setColorArray] = useState<string[]>([])
  const getColorArray = () => {
    // Adjust the total number of octagons based on screen size
    const totalOctagons = isMd ? 13 * 5 : 4 * 6 // 13x5 for desktop, 4x6 for mobile
    const colorsArray: string[] = []
    colors.forEach((color, key) => {
      const count = Math.floor(totalOctagons * colors[key].percentage)

      for (let i = 0; i < count; i++) {
        colorsArray.push(color.color)
      }
    })
    return colorsArray.sort(() => Math.random() - 0.5) // Shuffle the array
  }

  useEffect(() => {
    if (isMd) {
      setColorArray(getColorArray().slice(0, 65))
    } else {
      setColorArray(getColorArray().slice(0, 24))
    }
  }, [isMd]) // Recompute the color array when the screen size changes

  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const lastExecutionTime = useRef<number>(0)

  const handleHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    crossHover(event)
    const now = Date.now()

    // Check if enough time has passed since the last animation
    if (now - lastExecutionTime.current < 665) {
      // If not enough time has passed, set a delay for the next animation
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(
        () => {
          runAnimation(event)
          lastExecutionTime.current = Date.now()
        },
        665 - (now - lastExecutionTime.current),
      )
    } else {
      // Otherwise, run the animation immediately
      runAnimation(event)
      lastExecutionTime.current = Date.now()
    }
  }

  const crossHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const octagon = event.currentTarget
    const hoveredSvg = octagon?.querySelector("svg")?.parentElement
    const allOctons = hoveredSvg?.parentElement?.parentElement?.querySelectorAll(".octagon")
    const previousSibling = hoveredSvg?.previousElementSibling
    const nextSibling = hoveredSvg?.nextElementSibling

    const aboveSibling = isMd
      ? hoveredSvg?.previousElementSibling?.previousElementSibling?.previousElementSibling?.previousElementSibling
          ?.previousElementSibling?.previousElementSibling?.previousElementSibling?.previousElementSibling
          ?.previousElementSibling?.previousElementSibling?.previousElementSibling?.previousElementSibling
          ?.previousElementSibling
      : hoveredSvg?.previousElementSibling?.previousElementSibling?.previousElementSibling?.previousElementSibling
    const belowSibling = isMd
      ? hoveredSvg?.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling
          ?.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling
          ?.nextElementSibling?.nextElementSibling?.nextElementSibling
      : hoveredSvg?.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling

    if (allOctons) {
      Object.entries(allOctons).forEach(([_, oct]) => {
        oct.classList.remove("opacity-0")
      })
    }

    if (hoveredSvg) {
      hoveredSvg.classList.forEach((item) => {
        if (item.startsWith("text-")) hoveredSvg.classList.remove(item)
      })
      hoveredSvg.classList.remove("text-grey")
    }

    if (previousSibling) {
      previousSibling.classList.forEach((item) => {
        if (item.startsWith("text-")) previousSibling.classList.remove(item)
      })
      previousSibling.classList.remove("text-grey")
      previousSibling.classList.add("opacity-0")
    }

    if (nextSibling) {
      nextSibling.classList.forEach((item) => {
        if (item.startsWith("text-")) nextSibling.classList.remove(item)
      })
      nextSibling.classList.remove("text-grey")
      nextSibling.classList.add("opacity-0")
    }

    if (aboveSibling) {
      aboveSibling.classList.forEach((item) => {
        if (item.startsWith("text-")) aboveSibling.classList.remove(item)
      })
      aboveSibling.classList.remove("text-grey")
      aboveSibling.classList.add("opacity-0")
    }

    if (belowSibling) {
      belowSibling.classList.forEach((item) => {
        if (item.startsWith("text-")) belowSibling.classList.remove(item)
      })
      belowSibling.classList.remove("text-grey")
      belowSibling.classList.add("opacity-0")
    }
  }

  const runAnimation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const octagon = event.currentTarget

    if (gridContainer.current) {
      const index = Array.from(gridContainer.current.children).indexOf(octagon)
      const columns = isMd ? 13 : 4 // Adjust columns based on screen size
      const rows = isMd ? 5 : 6
      const targetRow = Math.floor(index / columns)
      const targetCol = index % columns
      const newColor = getRandomColor(true)

      gridContainer.current.childNodes.forEach((child, i) => {
        const row = Math.floor(i / columns)
        const col = i % columns
        const distance = Math.sqrt(Math.pow(row - targetRow, 2) + Math.pow(col - targetCol, 2))
        const delay = distance * 100

        setTimeout(() => {
          const svg = (child as HTMLElement).querySelector("svg")
          if (svg && child !== octagon) {
            const isDiagonalOrSameLine =
              row === targetRow || col === targetCol || Math.abs(row - targetRow) === Math.abs(col - targetCol)
            svg.parentElement?.classList.forEach((item) => {
              if (item.startsWith("text-")) svg.parentElement?.classList.remove(item)
            })
            if (isDiagonalOrSameLine) {
              svg.parentElement?.classList.add(`text-${newColor}`)
            } else {
              svg.parentElement?.classList.add(`text-${getRandomColor()}`)
            }
          }
        }, delay)
      })
    }
  }

  const trackCursor = () => {
    const octagons = document.querySelectorAll(".octagon")
    octagons.forEach((octagon) => {
      const rect = octagon.getBoundingClientRect()
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2
      const distance = Math.sqrt(Math.pow(centerX - cursorX, 2) + Math.pow(centerY - cursorY, 2))
      const detectionRadius = rect.width * 0.8

      const hoveredSvg = octagon.querySelector("svg")?.parentElement
      if (hoveredSvg && distance <= detectionRadius) {
        hoveredSvg.parentElement?.classList.forEach((item) => {
          if (item.startsWith("text-")) hoveredSvg.parentElement?.classList.remove(item)
        })

        hoveredSvg.parentElement?.classList.add(`text-grey`)
      }
    })
    requestAnimationFrame(trackCursor)
  }

  const getRandomColor = (excludeNone = false) => {
    let newColor
    let lastColor
    const colorPool = excludeNone ? colors.filter((c) => c.color !== "grey") : colors
    do {
      newColor = colorPool[Math.floor(Math.random() * colorPool.length)].color
    } while (newColor === lastColor)
    lastColor = newColor
    return newColor
  }

  const octonClick = () => {
    const octagons = document.querySelectorAll(".octagon")
    octagons.forEach((octagon) => {
      if (octagon.classList.contains("text-pink")) {
        octagon.classList.remove("text-pink")
        octagon.classList.add("text-green")
      } else if (octagon.classList.contains("text-green")) {
        octagon.classList.remove("text-green")
        octagon.classList.add("text-yellow")
      } else if (octagon.classList.contains("text-yellow")) {
        octagon.classList.remove("text-yellow")
        octagon.classList.add("text-orange")
      } else if (octagon.classList.contains("text-orange")) {
        octagon.classList.remove("text-orange")
        octagon.classList.add("text-blue")
      } else if (octagon.classList.contains("text-blue")) {
        octagon.classList.remove("text-blue")
        octagon.classList.add("text-pink")
      }
    })
  }

  useEffect(() => {
    document.addEventListener("mousemove", (event) => {
      cursorX = event.clientX
      cursorY = event.clientY
    })

    trackCursor()
  }, [])

  const [octalHeight, setOctalHeight] = useState<number>(0)

  const createOctagonSVG = (color: string, key: number) => {
    function getRow() {
      if (key >= 0 && key <= 65) {
        return Math.floor(key / 13) + 1
      }
    }

    return (
      <InnerOctal
        classes={`octagon text-${color} mix-blend-multiply row-no-${getRow()}`}
        onMouseOver={handleHover}
        first={key === 0 ? true : false}
        setOctalHeight={setOctalHeight}
        onClick={octonClick}
      />
    )
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const [extra, setExtra] = useState<string>("")

  useEffect(() => {
    if (!containerRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current?.clientHeight) {
        if (!isMd) {
          setExtra("")
        } else {
          if (containerRef.current.clientHeight < octalHeight * 6) {
            setExtra("hide-row-1")
          }
          if (containerRef.current.clientHeight < octalHeight * 5) {
            setExtra("hide-row-1 hide-row-2")
          }
          if (containerRef.current.clientHeight < octalHeight * 4) {
            setExtra("hide-row-1 hide-row-2 hide-row-3")
          }
          if (containerRef.current.clientHeight < octalHeight * 3) {
            setExtra("hide-row-1 hide-row-2 hide-row-3 hide-row-4")
          }
          if (containerRef.current.clientHeight >= octalHeight * 6) {
            setExtra("")
          }
        }
      }
    })
    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect()
  }, [octalHeight, isMd])

  const targetRef = useRef<HTMLDivElement | null>(null)
  const [hasfired, setHasfired] = useState<boolean>(false)
  const handleScroll = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect()
      if (rect.top <= 0) {
        if (isMd) {
          navStatus.setOpen(true)
          setHasfired(true)
        }
      }
    }
  }

  useEffect(() => {
    if (hasfired) return
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [hasfired, isMd])

  return (
    <>
      <div className="grid-auto items-center w-full px-5 md:px-4">
        <div className="col-span-12 flex justify-start">
          {env.admin ? (
            <>
              <EditableText
                className="type-title-m-light"
                store={usePostTitleEditor()}
                placeholder="Enter page title..."
                inlineToolbar={false}
              />
            </>
          ) : (
            <>
              <div className="type-title-m-light md:hidden">{props.page?.title}</div>
            </>
          )}
        </div>
        <div className="col-span-12 flex justify-start py-6 md:pt-0">
          <div
            ref={containerRef}
            className={`visualisation-container md:flex md:items-end ${extra}`}
            style={{
              height: isMd ? `calc(100dvh - (var(--spacing-headerHeight) + var(--spacing-4)))` : `auto`,
              maxHeight: `${octalHeight * 8}px`,
            }}
          >
            <div ref={gridContainer} className="visualisation">
              {colorArray.map((color, i) => (
                <Fragment key={i}>{createOctagonSVG(color, i)}</Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-yellow py-5" ref={targetRef}>
        <div className="flex flex-col md:flex-row w-full px-5 md:px-4 items-start gap-8">
          <div className="w-full h-full justify-start items-start md:w-1/2 max-w-[608px]">
            <EditableText
              as="h2"
              className="type-title-l-light md:type-title-xl-light"
              store="heading"
              placeholder="Enter heading..."
              inlineToolbar={false}
            />
          </div>

          <div className="hidden md:block flex-grow" />

          <div className="w-full h-full justify-start items-start md:w-1/2 max-w-[608px]">
            <EditableText
              as="p"
              className="type-body-m-regular md:type-body-m-light"
              store="content"
              placeholder="Enter content..."
              inlineToolbar={false}
            />
            <div className="w-full h-6" />
            <InnerBlocks
              allowedBlocks={["core/buttons"]}
              wrapBlock={(children, ctx) => <div className="[&>div>div]:!my-0">{children}</div>}
            />
          </div>
        </div>
      </div>
    </>
  )
})

const InnerOctal = ({
  classes,
  onMouseOver,
  first,
  setOctalHeight,
  onClick,
}: {
  classes: string
  onMouseOver: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  first?: boolean
  setOctalHeight: React.Dispatch<React.SetStateAction<number>>
  onClick: () => void
}) => {
  const elementRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!elementRef.current) return
    if (!first) return
    const resizeObserver = new ResizeObserver(() => {
      if (elementRef.current?.clientHeight) setOctalHeight(elementRef.current.clientHeight)
    })
    resizeObserver.observe(elementRef.current)
    return () => resizeObserver.disconnect()
  }, [first])

  return (
    <div ref={elementRef} className={classes} onMouseOver={(e) => onMouseOver(e)} onClick={() => onClick()}>
      {OCTAL}
    </div>
  )
}
